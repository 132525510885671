$modal-padding:                     1.5rem !default;
$modal-padding-xs:                  15px !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-bg:                  $white !default;
$modal-content-box-shadow:          0px 8px 14px 4px rgba(0, 0, 0, 0.13) !default;
$modal-content-padding:             1.5rem !default;
$modal-content-padding-lg:          3rem !default;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            0.5 !default;

$modal-max-width-xl:                1600px !default;
$modal-max-width-lg:                800px !default;
$modal-max-width-base:              500px !default;
$modal-max-width-sm:                300px !default;

$modal-transition:                  transform 0.3s ease-out !default;
