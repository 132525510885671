/*
  Dropdown

  Fancy dropdown to be used in place of a standard `<select>`. One selection only (i.e. no `multiple` support).

  *Dropdown requires the JS module `Dropdown`.*

  *HolmgrensVue offers a Vue implementation for this component: `VcDropdown`.*

  Markup: ../docs/markup/components/forms/controls/dropdown.hbs

  Styleguide components.forms.controls.dropdown
*/

@keyframes dropdown-fade-in {
  0% {
    display: none;
    opacity: 0;
    transform: translateY(25%);
  }

  1% {
    display: block;
    opacity: 0;
    transform: translateY(25%);
  }

  100% {
    display: block;
    opacity: 1;
    transform: translateY(0);
  }
}

.#{$ns}dropdown {
  position: relative;
  color: $input-placeholder-color;
  cursor: pointer;

  select {
    display: none;
  }

  &__selected,
  &__item {
    @extend .#{$ns}form-control;
  }

  &__selected {
    @extend .#{$ns}icon;
    @extend .#{$ns}icon-angle;

    &::before {
      position: absolute;
      right: space(3);
      display: inline-block;
      font-size: 1.7rem;
      transform: rotate(90deg);
    }
  }

  &__list {
    @extend .#{$ns}list-unstyled;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: $zindex-dropdown;
    display: none;
    opacity: 0;
    animation: dropdown-fade-in 0.22s ease-out;
  }

  &__item {
    position: relative;
    // stylelint-disable-next-line declaration-no-important
    border-top: 0 !important;

    &--active {
      @extend .#{$ns}icon;
      @extend .#{$ns}icon-checkmark;

      &::before {
        position: absolute;
        right: space(3);
        font-size: 1.7rem;
      }
    }
  }

  &--active,
  &--selected {
    color: $input-color;
  }

  &--active {
    .#{$ns}dropdown__selected {
      &::before {
        transform: rotate(270deg);
      }
    }

    .#{$ns}dropdown__list {
      display: block;
      opacity: 1;
    }

    .#{$ns}dropdown__selected,
    .#{$ns}dropdown__item {
      background: $input-focus-bg;
      border-color: $input-focus-border-color;
    }
  }
}
