/*
  Body text

  Markup: ../docs/markup/elements/text.hbs

  Styleguide elements.text
*/

.lead {
  font-size: $lead-font-size;
  font-weight: $lead-font-weight;
  line-height: $lead-line-height;
}

p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
  line-height: $line-height-base;

  &:last-child {
    margin-bottom: 0;
  }
}

small,
.small {
  font-size: $small-font-size;
  font-weight: $font-weight-normal;
}

mark,
.mark {
  padding: 0 0.3em;
  background-color: color("secondary");
}

