/*
  Progress

  A wizard-like progress bar that toggles content depending on the current step.

  *Progress requires the JS module `Progress`.*

  Markup: ../docs/markup/components/progress.hbs

  Styleguide components.progress
*/

.#{$ns}progress {
  background: color("white");
  border-bottom: $border-width solid $border-color;

  &__step-container {
    display: flex;
    counter-reset: progstep;
    border-bottom: $border-width-lg solid $border-color;
  }

  &__step {
    @extend .#{$ns}icon;
    @extend .#{$ns}icon-checkmark;
    position: relative;
    display: flex;
    flex: 1 0 0;
    justify-content: center;
    padding: map-get($spacers, 3) 0.5rem;
    font-size: $font-size-xs;
    color: color("gray-dark");
    text-decoration: none;
    cursor: pointer;
    counter-increment: progstep;

    @include media-breakpoint-up(sm) {
      padding: space(4) space(6);
      font-size: $font-size-lg;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }

    &::after {
      position: absolute;
      bottom: -$border-width-lg;
      left: 0;
      display: block;
      width: 100%;
      height: $border-width-lg;
      content: "";
      background: $border-active-color;
    }

    &::before {
      position: absolute;
      bottom: -(($progress-badge-size / 2) + ($border-width-lg / 2));
      left: calc(50% - #{$progress-badge-size / 2});
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: $progress-badge-size;
      height: $progress-badge-size;
      font-weight: $font-weight-black;
      color: $body-color;
      background: $border-active-color;
      border-radius: 50%;
    }

    &--active {
      font-weight: $font-weight-bold;
      color: $body-color;
      cursor: default;

      &::after {
        width: 50%;
      }

      &::before,
      ~ .#{$ns}progress__step::before {
        // stylelint-disable-next-line declaration-no-important
        font-family: $font-family-base !important;
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        content: counter(progstep);
      }

      ~ .#{$ns}progress__step {
        &::after {
          width: 0;
        }

        &::before {
          background: $border-color;
        }
      }
    }
  }
}

.#{$ns}progress-content {
  display: none;
  padding: map-get($spacers, 5);

  &--active {
    display: block;
  }
}
