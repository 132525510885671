/*
  Variables

  All variables are declared `!default` for overridability.

  Variables should follow the `$component-state-property-size` formula for consistent naming, e.g. `$nav-link-disabled-color` and `$modal-content-box-shadow-xs`.

  Styleguide settings.variables
*/


@import "variables/colors";
@import "variables/spacers";
@import "variables/grid";
@import "variables/borders";
@import "variables/box-shadows";
@import "variables/transitions";
@import "variables/type";
@import "variables/body";
@import "variables/buttons";
@import "variables/forms";
@import "variables/modals";
@import "variables/progress";
@import "variables/loader";
@import "variables/notifications";
@import "variables/color-picker";
@import "variables/links";
@import "variables/zindex";
