// stylelint-disable selector-no-qualifying-type

/*
  Buttons

  Use buttons for actions in forms, dialogs, and more with support for multiple sizes and states.

  Markup: <button class="btn {{modifier_class}}">Button</button>

  .btn--primary - Display a primary action.
  .btn--secondary - Display a secondary action.
  .btn--success - Display a safe action.
  .btn--danger - Display a dangerous action.
  .btn--outline - Display a low priority action.

  Styleguide components.buttons
*/

@mixin button-size($padding-y, $padding-x, $font-size, $font-weight, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  border-radius: $border-radius;
}

.#{$ns}btn {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-font-weight, $btn-line-height, $btn-border-radius);
  transition: $btn-transition;

  // Share hover and focus styles
  &,
  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
  }

  // Opinionated: add "hand" cursor to non-disabled .btn elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.#{$ns}btn.disabled {
  pointer-events: none;
}

// Alternate buttons

@mixin button-variant($background, $color, $hover-background: darken($background, 9%), $hover-color: darken($color, 5%), $active-background: darken($background, 10%), $active-border: $active-background) {
  color: $color;
  background: $background;
  border-color: $background;

  &:hover {
    color: $hover-color;
    background: $hover-background;
    border-color: $hover-background;
  }

  // Unset hover styling for mobile browsers that support mq hover, prevents stickiness
  @media (hover: none) {
    &:hover {
      color: $color;
      background: $background;
      border-color: $background;
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    color: color("gray-dark");
    background-color: color("gray-light");
    border-color: color("gray-dark");
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;
  }
}

.#{$ns}btn--primary {
  @include button-variant(color("primary"), color("secondary"), color("secondary"), color("primary"));
}

.#{$ns}btn--secondary {
  @include button-variant(color("secondary"), color("primary"), color("primary"), color("secondary"));
}

.#{$ns}btn--success {
  @include button-variant(color("success"), color("white"));
}

.#{$ns}btn--danger {
  @include button-variant(color("danger"), color-yiq(color("danger")));
}

.#{$ns}btn--white {
  @include button-variant(color("black"), color("white"));
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $active-background: $color, $active-border: $color) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  @media (hover: none) {
    &:hover {
      color: $color;
      background-color: transparent;
      border-color: $color;
    }
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;
  }
}

.#{$ns}btn--outline {
  @include button-outline-variant(color("primary"), color("secondary"));
}

/*
  Sizing

  Markup: <button class="btn btn--primary {{modifier_class}}">Button</button>

  .btn--sm - Make the button small.
  .btn--lg - Make the button large.
  .btn--block - Make it 100% wide.

  Styleguide components.buttons.sizing
*/

.#{$ns}btn--lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-font-weight-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.#{$ns}btn--sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-font-weight-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

.#{$ns}btn--block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .#{$ns}btn--block {
    margin-top: $btn-block-spacing-y;
  }
}

/*
  Circle

  Use it for simple actions that don't need explaining, such as closing a dialog.

  Markup: <button class="btn btn--circle btn--primary"><i class="icon-close"></i></button>

  Styleguide components.buttons.circle
*/

.#{$ns}btn--circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  padding: 0;
  line-height: 0;
  border-radius: 100%;

  i {
    font-size: 2rem;
  }

  &.#{$ns}btn--sm {
    width: 1.75rem;
    height: 1.75rem;

    i {
      font-size: 0.75rem;
    }
  }
}

/*
  Icon

  Add an icon before the text.

  Markup: <button class="btn btn--icon btn--primary"><i class="icon-twitter"></i> Tweet it!</button>

  Weight: -1

  Styleguide components.buttons.icon
*/

.#{$ns}btn--icon {
  position: relative;
  padding-left: 2.9rem;

  i {
    position: absolute;
    top: 0.8rem;
    left: 1rem;
    font-size: 1.8rem;
    line-height: 2rem;
  }

  &.#{$ns}btn--sm {
    padding-left: 2.75rem;

    i {
      top: 0.5rem;
      left: 0.8rem;
      font-size: 1.8rem;
    }
  }

  &.#{$ns}btn--lg {
    padding-left: 3.2rem;

    i {
      top: 0.8rem;
      left: 1.1rem;
      font-size: 2rem;
    }
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.#{$ns}btn--block {
    width: 100%;
  }
}
