$label-margin-bottom:                   0.5rem !default;

$input-padding-y:                       0.75rem !default;
$input-padding-x:                       0.75rem !default;
$input-line-height:                     1.5 !default;

$input-padding-y-sm:                    0.5625rem !default;
$input-padding-x-sm:                    0.5625rem !default;
$input-line-height-sm:                  $input-line-height !default;

$input-padding-y-lg:                    $input-padding-y !default;
$input-padding-x-lg:                    $input-padding-x !default;
$input-line-height-lg:                  $input-line-height !default;

$input-bg:                              $white !default;
$input-disabled-bg:                     $gray-100 !default;
$input-disabled-opacity:                0.5 !default;

$input-color:                           inherit !default;
$input-border-color:                    $gray-700 !default;
$input-border-width:                    $border-width !default;
$input-box-shadow:                      none !default;

$input-border-radius:                   0 !default;
$input-border-radius-lg:                $input-border-radius !default;
$input-border-radius-sm:                $input-border-radius !default;

$input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              inherit !default;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     0 !default;
$input-focus-box-shadow:                none !default;

$input-placeholder-color:               $gray-700 !default;
$input-plaintext-color:                 $body-color !default;

$input-height-border:                   $input-border-width * 2 !default;

$input-height-inner:                    ($font-size-base * $input-line-height) + ($input-padding-y * 2) !default;
$input-height:                          calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm:                 ($font-size-sm * $input-line-height-sm) + ($input-padding-y-sm * 2) !default;
$input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;

$input-height-inner-lg:                 ($font-size-lg * $input-line-height-lg) + ($input-padding-y-lg * 2) !default;
$input-height-lg:                       calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition:                      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-text-color:                       $text-muted !default;
$form-text-font-size:                   $font-size-xs !default;
$form-text-margin-top:                  0.25rem !default;

$form-check-input-gutter:               1.75rem !default;
$form-check-input-margin-y:             0.2rem !default;
$form-check-input-margin-x:             0.25rem !default;

$form-check-inline-margin-x:            0.75rem !default;
$form-check-inline-input-margin-x:      0.3125rem !default;

$form-group-margin-bottom:              1.5rem !default;

$form-group-label-font-size:            $font-size-sm !default;

$input-group-border-radius:             $btn-border-radius !default;
$input-group-border-radius-sm:          $input-group-border-radius !default;
$input-group-border-radius-lg:          $input-group-border-radius !default;

$input-group-addon-color:               $input-color !default;
$input-group-addon-bg:                  $gray-300 !default;
$input-group-addon-border-color:        $input-border-color !default;

// Form validation

$form-feedback-margin-top:              $form-text-margin-top !default;
$form-feedback-font-size:               $small-font-size !default;
$form-feedback-valid-color:             map-get($colors, "success") !default;
$form-feedback-invalid-color:           map-get($colors, "danger") !default;
