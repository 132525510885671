// stylelint-disable

/*
  Icons

  Icons can be used directly as in the example or like `@extend .icon; @extend .icon-angle;`.

  Markup: ../docs/markup/components/icons.hbs

  Styleguide components.icons
*/

[class^="#{$ns}icon-"], [class*=" #{$ns}icon-"], .#{$ns}icon {
  &:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
  }
}

.#{$ns}icon-angle-circle:before {
  content: "\e900";
}
.#{$ns}icon-angle-fill:before {
  content: "\e901";
}
.#{$ns}icon-angle:before {
  content: "\e902";
}
.#{$ns}icon-calendar:before {
  content: "\e903";
}
.#{$ns}icon-checkmark-circle:before {
  content: "\e904";
}
.#{$ns}icon-checkmark:before {
  content: "\e905";
}
.#{$ns}icon-close-circle:before {
  content: "\e906";
}
.#{$ns}icon-close:before {
  content: "\e907";
}
.#{$ns}icon-door:before {
  content: "\e908";
}
.#{$ns}icon-enlarge:before {
  content: "\e909";
}
.#{$ns}icon-facebook:before {
  content: "\e90a";
}
.#{$ns}icon-fuel:before {
  content: "\e90b";
}
.#{$ns}icon-gearbox:before {
  content: "\e90c";
}
.#{$ns}icon-help:before {
  content: "\e90d";
}
.#{$ns}icon-photo:before {
  content: "\e90e";
}
.#{$ns}icon-play:before {
  content: "\e90f";
}
.#{$ns}icon-safety:before {
  content: "\e910";
}
.#{$ns}icon-speedometer:before {
  content: "\e911";
}
.#{$ns}icon-trunk:before {
  content: "\e912";
}
.#{$ns}icon-twitter:before {
  content: "\e913";
}
.#{$ns}icon-year:before {
  content: "\e914";
}
