// stylelint-disable

/*
  Legacy icons

  Icons previously used on Holmgrens web sites are included through `.hicon-`

  Markup: <i class="hicon-sort-asc"></i>

  Styleguide components.icons.legacy
*/

[class^="#{$ns}hicon-"]:before,
[class*=" #{$ns}hicon-"]:before,
[class^="#{$ns}hicon-"]:after,
[class*="#{$ns}hicon-"]:after,
.#{$ns}hicon:before {
    font-family: "holmgrens-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em
}

.#{$ns}hicon-menu:before {
    content: '\e62a';
}

.#{$ns}hicon-up:before {
    content: '\e621';
}

.#{$ns}hicon-sort-asc:before {
    content: '\e629';
}

.#{$ns}hicon-sort-desc:before {
    content: '\e628';
}

.#{$ns}hicon-combo:before {
    content: '\e80b';
}

.#{$ns}hicon-star:before {
    content: '\e80a';
}

.#{$ns}hicon-star-filled:before {
    content: '\e606';
}

.#{$ns}hicon-place:before {
    content: '\e803';
}

.#{$ns}hicon-cartype:before {
    content: '\e806';
}

.#{$ns}hicon-facebook:before {
    content: '\e61b';
}

.#{$ns}hicon-twitter:before {
    content: '\e601';
}

.#{$ns}hicon-instagram:before {
    content: '\e616';
}

.#{$ns}hicon-grid:before {
    content: '\e807';
}

.#{$ns}hicon-list:before {
    content: '\e808';
}

.#{$ns}hicon-left:before {
    content: '\e60c';
    transform: rotate(-180deg);
}

.#{$ns}hicon-right:before {
    content: '\e60c';
}

.#{$ns}hicon-search:before {
    content: '\e607';
}

.#{$ns}hicon-arrow-right:after {
    content: '\e60c';
}

.#{$ns}hicon-edit:before {
    content: '\e627';
}

.#{$ns}hicon-menu-arrow:before {
    content: '\e609';;
}

.#{$ns}hicon-campaign:before {
    content: '\e613';
}

.#{$ns}hicon-menu-home:before {
    content: '\e651';
}

.#{$ns}hicon-menu-car:before {
    content: '\e623';
}

.#{$ns}hicon-menu-service:before {
    content: '\e608';
}

.#{$ns}hicon-menu-shop:before {
    content: '\e611';
}

.#{$ns}hicon-menu-school:before {
    content: '\e618';
}

.#{$ns}hicon-menu-contact:before {
    content: '\e619';
}

.#{$ns}hicon-contact-tyres:before {
    content: '\e61e';
}

.#{$ns}hicon-contact-damages:before {
    content: '\e610';
}

.#{$ns}hicon-contact-service:before {
    content: '\e60b';
}

.#{$ns}hicon-contact-sales:before {
    content: '\e623';
}

.#{$ns}hicon-contact-rent:before {
    content: '\e626';
}

.#{$ns}hicon-contact-parts:before {
    content: '\e615';
}

.#{$ns}hicon-contact-recond:before {
    content: '\e600';
}

.#{$ns}hicon-contact-call:before {
    content: '\e614';
}

.#{$ns}hicon-contact-get-call:before {
    content: '\e604';
}

.#{$ns}hicon-contact-mail:before {
    content: '\e60d';
}

.#{$ns}hicon-contact-drive:before {
    content: '\e622';
}

.#{$ns}hicon-contact-meet:before {
    content: '\e602';
}

.#{$ns}hicon-contact-chat:before {
    content: '\e61f';
}

.#{$ns}hicon-contact-chat2:before {
    content: '\e619';
}

.#{$ns}hicon-contact-finance:before {
    content: '\e652';
}

.#{$ns}hicon-service-tyres:before {
    content: '\e61e';
}

.#{$ns}hicon-service-damages:before {
    content: '\e610';
}

.#{$ns}hicon-service-shop:before {
    content: '\e611';
}

.#{$ns}hicon-service-service:before {
    content: '\e60b';
}

.#{$ns}hicon-service-recond:before {
    content: '\e600';
}

.#{$ns}hicon-divider-right:before {
    content: '\e625';
}

.#{$ns}hicon-divider-left:before {
    content: '\e61d';
}

.#{$ns}hicon-divider-down:before {
    content: '\e624';
}

.#{$ns}hicon-divider-up:before {
    content: '\e620';
}

.#{$ns}hicon-combo:before {
    content: '\e605';
}

.#{$ns}hicon-list:before {
    content: '\e60f';
}

.#{$ns}hicon-grid:before {
    content: '\e61a';
}

.#{$ns}hicon-plus:after {
    content: '\e61c';
}

.#{$ns}hicon-minus:after {
    content: '\e603';
}

.#{$ns}hicon-plus-thin:before {
    content: '\e62f';
}

.#{$ns}hicon-minus-thin:before {
    content: '\e62e';
}

.#{$ns}hicon-chassis:before {
    content: '\e62d';
}

.#{$ns}hicon-new01:before {
    content: '\e630';
}

.#{$ns}hicon-A:before {
    content: '\e631';
}

.#{$ns}hicon-filter-control:before {
    content: '\e632';
}

.#{$ns}hicon-sheet:before {
    content: '\e633';
}

.#{$ns}hicon-marker:before {
    content: "";
}

.#{$ns}hicon-thin-arrow-up:before {
    content: '\e64d';
}

.#{$ns}hicon-thin-arrow-down:before {
    content: '\e634';
}

.#{$ns}hicon-thin-arrow-left:before {
    content: '\e635';
}

.#{$ns}hicon-thin-arrow-right:before {
    content: '\e636';
}

.#{$ns}hicon-thin-close:before {
    content: '\e637';
}

.#{$ns}hicon-thin-search:before {
    content: '\e638';
}

.#{$ns}hicon-thin-list:before {
    content: '\e639';
}

.#{$ns}hicon-thin-list2:before {
    content: '\e63a';
}

.#{$ns}hicon-thin-grid:before {
    content: '\e63b';
}

.#{$ns}hicon-thin-callout:before {
    content: '\e63c';
}

.#{$ns}hicon-thin-callin:before {
    content: '\e63d';
}

.#{$ns}hicon-thin-filter-control:before {
    content: '\e63e';
}

.#{$ns}hicon-thin-contact:before {
    content: '\e63f';
}

.#{$ns}hicon-thin-chat:before {
    content: '\e640';
}

.#{$ns}hicon-thin-member:before {
    content: '\e641';
}

.#{$ns}hicon-thin-calendar:before {
    content: '\e642';
}

.#{$ns}hicon-thin-shop:before {
    content: '\e643';
}

.#{$ns}hicon-thin-fav:before {
    content: '\e644';
}

.#{$ns}hicon-thin-checkin:before {
    content: '\e645';
}

.#{$ns}hicon-thin-clock:before {
    content: '\e646';
}

.#{$ns}hicon-thin-doc:before {
    content: '\e647';
}

.#{$ns}hicon-thin-setting:before {
    content: '\e648';
}

.#{$ns}hicon-thin-contact-service:before {
    content: '\e649';
}

.#{$ns}hicon-thin-checkin2:before {
    content: '\e64a';
}

.#{$ns}hicon-thin-car:before {
    content: '\e64b';
}

.#{$ns}hicon-thin-share:before {
    content: '\e64c';
}

.#{$ns}hicon-thin-menu:before {
    content: '\e64f';
}

.#{$ns}hicon-thin-trash:before {
    content: '\e650';
}

.#{$ns}hicon-shopcart:before {
    content: '\e969';
}

.#{$ns}hicon-finance:before {
    content: '\e652';
}

.#{$ns}hicon-radio:before {
    content: '\e653';
}

.#{$ns}hicon-radio-checked:before {
    content: '\e655';
}
