/*
  Grid

  Generate semantic grid columns with these mixins.

  ```css
  .example-container {
    @include make-container();
  }

  .example-row {
    @include make-row();
  }

  .example-col {
    @include make-col-ready();

    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-up(lg) {
      @include make-col(8);
    }
  }
  ```

  Styleguide tools.mixins.grid
*/

/*
  make-container()

  Creates a container, the main wrapper.

  Styleguide tools.mixins.grid.make-container
*/

@mixin make-container() {
  width: 100%;
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-up(lg) {
    padding-right: ($grid-gutter-width * 2);
    padding-left: ($grid-gutter-width * 2);
  }
}


// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

/*
  make-row()

  Creates a row, a wrapper for a series of columns.

  Styleguide tools.mixins.grid.make-row
*/

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
}

/*
  make-col-ready()

  Make the element grid-ready. (applying everything but the width)

  Styleguide tools.mixins.grid.make-col-ready
*/

@mixin make-col-ready() {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;
  min-height: 1px; // Prevent collapsing
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
}

/*
  make-col($size)

  Define the column width, use in conjunction with breakpoint mixins to achieve responsive layout.

  Styleguide tools.mixins.grid.make-col
*/

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

/*
  make-col-offset($size)

  Get fancy by offsetting, or changing the sort order.

  Styleguide tools.mixins.grid.make-col-offset
*/

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}
