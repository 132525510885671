/*
  Font faces

  The declaration of `@font-face` for the primary font and icon fonts:

  * Museo Sans
  * Icomoon
  * Legacy Icomoon

  You need to define `$wdk-font-path` before importing this file for the paths to work.

  Styleguide settings.font-faces
*/

$wdk-font-path: "../../fonts" !default;

@import "font-faces/museo-sans";
@import "font-faces/holmgrens-icons";
@import "font-faces/icomoon";
