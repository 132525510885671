/*
  Typography

  All sizes are defined using `rem`, which allows the page resize correctly based on the user's settings. `1rem` typically equals `16px` as the browser default.

  Styleguide settings.variables.typography
*/

/*
  Font families

  `$font-family-base` is the main variable for handling the site font.

  `$font-family-monospace` is unused, but can be used for things like `<code>` blocks.

  Styleguide settings.variables.typography.font-families
*/

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Museo Sans", "Helvetica Neue", Arial, sans-serif !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case


/*
  Font sizes

  `$font-size-base` for body text

  `$font-size-lg` for large text, e.g. `.lead`

  `$font-size-sm` for small text, e.g. `<small>`

  `$font-size-xs` for meta text, e.g. `.form-text`

  Styleguide settings.variables.typography.font-sizes
*/

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * 0.875) !default;
$font-size-xs:                ($font-size-base * 0.75) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          500 !default;
$font-weight-bold:            700 !default;
$font-weight-black:           900 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;
$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;


/*
  Headings

  Each heading has variables for its `font-size` and `line-height`:

  `$h1-font-size`

  `$h1-line-height`

  And it goes on for `h1-6`.

  All headings have `margin-bottom: 0.5rem` which equals `8px`.

  There are also corresponding classes (`.h1`, `.h2` etc.) which can be used to style elements.

  Styleguide settings.variables.typography.headings
*/

$h1-font-size:                $font-size-base * 2.875 !default;
$h2-font-size:                $font-size-base * 2.125 !default;
$h3-font-size:                $font-size-base * 1.625 !default;
$h4-font-size:                $font-size-base * 1.25 !default;
$h5-font-size:                $font-size-base !default;
$h6-font-size:                $font-size-base !default;

$h1-line-height:              $font-size-base * 3.375 !default;
$h2-line-height:              $font-size-base * 2.625 !default;
$h3-line-height:              $font-size-base * 2 !default;
$h4-line-height:              $font-size-base * 1.75 !default;
$h5-line-height:              $font-size-base * 1.5 !default;
$h6-line-height:              $font-size-base * 1.5 !default;

$headings-margin-bottom:      ($spacer / 2) !default;
$headings-font-family:        inherit !default;
$headings-font-weight:        500 !default;
$headings-color:              inherit !default;

$lead-font-size:              $font-size-lg !default;
$lead-font-weight:            300 !default;
$lead-line-height:            1.4 !default;

$small-font-size:             $font-size-sm !default;

$text-muted:                  $gray-700 !default;

$paragraph-margin-bottom:     2rem !default;
