/*
  Card

  Markup: ../docs/markup/components/card.hbs

  .card--centered       - Center header and content.
  .card--sm             - Reduce padding and size.

  Styleguide components.card
*/

.card {
  &__header,
  &__content {
    padding: space(4);
  }

  &__header {
    font-weight: $font-weight-bold;
    background: color("secondary");

    ~ .card__content {
      border-top: 0;
    }
  }

  &__content {
    background: color("white");
    border: $border-width solid $border-color;
  }

  &--centered {
    text-align: center;
  }

  &--sm {
    .card__header,
    .card__content {
      padding: space(3) space(4);
      font-size: $font-size-sm;
    }
  }
}
