// stylelint-disable selector-no-qualifying-type

/*
  Forms

  Markup: ../docs/markup/components/forms.hbs

  Styleguide components.forms
*/

/*
  Controls

  Weight: -2

  Styleguide components.forms.controls
*/

.#{$ns}form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-base;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  box-shadow: $input-box-shadow;
  transition: $input-transition;

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-box-shadow, $input-focus-box-shadow;
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  &:disabled,
  &[readonly] {
    pointer-events: none;
    background-color: $input-disabled-bg;
    opacity: $input-disabled-opacity;
  }
}

/*
  Select

  Dropdown is preferred but you can use this simple select if you need something lightweight.

  Markup: ../docs/markup/components/forms/controls/select.hbs

  Styleguide components.forms.controls.select
*/

select.#{$ns}form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }

  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $input-color;
    background-color: $input-bg;
  }
}

// Make file inputs better match text inputs by forcing them to new lines.
.#{$ns}form-control-file,
.#{$ns}form-control-range {
  display: block;
  width: 100%;
}

/*
  Sizing

  Build on `.form-control` with modifier classes to decrease or increase the
  height and font-size of form controls.

  Markup: ../docs/markup/components/forms/controls/sizing.hbs

  .form-control-sm   - Small form control.
  .form-control-lg   - Large form control.

  Styleguide components.forms.controls.sizing
*/

.#{$ns}form-control-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $font-size-sm;
  line-height: $input-line-height-sm;
  border-radius: $input-border-radius-sm;
}

select.#{$ns}form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

.#{$ns}form-control-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $font-size-lg;
  line-height: $input-line-height-lg;
  border-radius: $input-border-radius-lg;
}

select.#{$ns}form-control-lg {
  &:not([size]):not([multiple]) {
    height: $input-height-lg;
  }
}


// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.#{$ns}form-group {
  margin-bottom: $form-group-margin-bottom;

  label {
    font-size: $form-group-label-font-size;
  }
}

.#{$ns}form-text {
  display: block;
  margin-top: $form-text-margin-top;
  font-size: $form-text-font-size;
  color: $form-text-color;
}

/*
  Radio and checkboxes

  Markup: ../docs/markup/components/forms/controls/radio-and-checkboxes.hbs

  Styleguide components.forms.controls.radio-and-checkboxes
*/

.#{$ns}form-check {
  position: relative;
  display: block;
  padding-left: $form-check-input-gutter;
}

.#{$ns}form-check-input {
  position: absolute;
  width: 19px;
  height: 19px;
  margin-top: $form-check-input-margin-y;
  margin-left: -$form-check-input-gutter;
  background-color: color("white");
  border: $input-border-width solid $input-border-color;
  appearance: none;

  &:disabled ~ .#{$ns}form-check-label {
    color: $text-muted;
  }

  &:checked ~ .#{$ns}form-check-label {
    font-weight: $font-weight-bold;
  }

  &[type="checkbox"] {
    border-radius: 0;
  }

  &[type="radio"] {
    border-radius: 50%;
  }

  &[type="checkbox"],
  &[type="radio"] {
    &:checked {
      @extend .#{$ns}icon;
      @extend .#{$ns}icon-checkmark;
      border-color: $input-focus-border-color;

      &::before {
        position: absolute;
        margin-top: 1px;
      }
    }
  }
}

.#{$ns}form-check-label {
  margin-top: 0;
  margin-bottom: 0; // Override default `<label>` bottom margin
}

/*
  Inline controls

  Markup: ../docs/markup/components/forms/controls/radio-and-checkboxes/inline-controls.hbs

  Styleguide components.forms.controls.radio-and-checkboxes.inline-controls
*/

.#{$ns}form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0; // Override base .form-check
  margin-right: $form-check-inline-margin-x;

  // Undo .form-check-input defaults and add some `margin-right`.
  .#{$ns}form-check-input {
    position: static;
    margin-top: 0;
    margin-right: $form-check-inline-input-margin-x;
    margin-left: 0;
  }
}


/*
  Validation

  Apply `.js-needs-validation` to provide feedback to users when form field values are invalid.

  The `submit` event will automatically add `.was-validated` when the form is validated.

  Use `novalidate` to stop the default browser validation messages.

  *Form validation requires the JS module `Form`.*

  Markup: ../docs/markup/components/forms/validation.hbs

  Weight: -1

  Styleguide components.forms.validation
*/

@mixin form-validation-state($state, $color) {
  .#{$state}-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    font-size: $form-feedback-font-size;
    color: $color;
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: $zindex-form-validation-tooltip;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1;
    color: $white;
    background-color: rgba($color, 0.8);
    border-radius: 0.2rem;
  }

  .#{$ns}form-control {
    .#{$ns}was-validated &:#{$state},
    &.#{$ns}is-#{$state} {
      border-color: $color;

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }

  .#{$ns}input-group {
    .#{$ns}was-validated &:#{$state},
    &.#{$ns}is-#{$state} {
      ~ .#{$ns}#{$state}-feedback,
      ~ .#{$ns}#{$state}-tooltip {
        display: block;
      }
    }
  }

  .#{$ns}form-check-input {
    .#{$ns}was-validated &:#{$state},
    &.#{$ns}is-#{$state} {
      ~ .#{$ns}form-check-label {
        color: $color;
      }

      ~ .#{$ns}#{$state}-feedback,
      ~ .#{$ns}#{$state}-tooltip {
        display: block;
      }
    }
  }
}

// @include form-validation-state("valid", $form-feedback-valid-color);
@include form-validation-state("invalid", $form-feedback-invalid-color);


/*
  Inline forms

  Make forms appear inline by adding the `.form-inline` class.

  Inline forms begin stacked on `xs` devices and then go inline when viewports reach the `sm` breakpoint.

  Markup: ../docs/markup/components/forms/inline-forms.hbs

  Styleguide components.forms.inline-forms
*/

.#{$ns}form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

  // Because we use flex, the initial sizing of checkboxes is collapsed and
  // doesn't occupy the full-width (which is what we want for xs grid tier),
  // so we force that here.
  .#{$ns}form-check {
    width: 100%;
  }

  // Kick in the inline
  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block all the things for "inline"
    .#{$ns}form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Allow folks to *not* use `.form-group`
    .#{$ns}form-control {
      display: inline-block;
      width: auto; // Prevent labels from stacking above inputs in `.form-group`
      margin-right: 1rem;
      vertical-align: middle;
    }

    // Make static controls behave like regular ones
    .#{$ns}form-control-plaintext {
      display: inline-block;
    }

    .#{$ns}input-group {
      width: auto;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .#{$ns}form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .#{$ns}form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }
  }
}
