/*
  Spacers

  Predefined spacers for paddings and margins.

  There are 6 different spacers as shown below. Sticking to these will make sure the site layout stays consistent. Use the convenient `space()` function.

  The default base value for `$spacer` is `1rem`, which corresponds to the third spacer:

  ```css
  space(1) // 0.25rem
  space(2) // 0.5rem
  space(3) // 1rem
  space(4) // 1.5rem
  space(5) // 2rem
  space(6) // 3rem
  ```

  Weight: 1

  Styleguide settings.variables.spacers
*/

// stylelint-disable
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3)
), $spacers);
