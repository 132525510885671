/*
  Theme grays

  Allows for fine-tuning shades of gray. Can be fetched with a function: `color: gray(300)`.

  gray-100   -  #fbfbfb; Light
  gray-300   -  #ededed; Base
  gray-700   -  #999; Dark
  gray-900   -  #313131; Black

  Weight: -1

  Styleguide settings.variables.theme-grays
*/

$white:    #fff !default;
$gray-100: #fbfbfb !default;
$gray-300: #ededed !default;
$gray-700: #999 !default;
$gray-900: #313131 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "300": $gray-300,
    "700": $gray-700,
    "900": $gray-900
  ),
  $grays
);

/*
  Theme colors

  The colors used for branding. Can be fetched with a function: `color: color("secondary")`.

  blue         -  #004a61; Primary
  yellow       -  #fdd430; Secondary
  green        -  #94e36a; Success
  red          -  #ff4401; Danger
  gold         -  #e7c230; Gold
  gray-light   -  #fbfbfb; Gray Light
  gray         -  #ededed; Gray Base
  gray-dark    -  #999; Gray Dark

  Weight: -1

  Styleguide settings.variables.theme-colors
*/

$blue:        #004a61 !default;
$red:         #ff4401 !default;
$gold:      #e7c230 !default;
$yellow:      #fdd430 !default;
$green:       #94e36a !default;

$primary:       $blue !default;
$secondary:     $yellow !default;
$success:       $green !default;
$danger:        $red !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "red":        $red,
    "yellow":     $yellow,
    "green":      $green,
    "gold":       $gold,
    "white":      $white,
    "black":      $black,
    "gray-light": $gray-100,
    "gray":       $gray-300,
    "gray-dark":  $gray-700,
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "danger":     $danger
  ),
  $colors
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;
