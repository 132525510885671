/*
  Color picker

  A list of colors (or any other images) to choose from. One selection only.

  *Color picker requires the JS module `ColorPicker`.*

  *HolmgrensVue offers a Vue implementation for this component: `VcColorPicker`.*

  Markup: ../docs/markup/components/color-picker.hbs

  Styleguide components.color-picker
*/

.#{$ns}color-picker {
  position: relative;
  padding: space(4);
  background: color("white");
  border: $border-width solid $border-color;

  &__items {
    padding-left: 0;
    margin: 0 0 0 (-$border-width-lg);
    list-style: none;
  }

  &__selected {
    padding-left: space(4);
    margin-top: space(3);
    font-weight: $font-weight-bold;
    color: $body-color;

    label,
    input {
      display: none;
    }

    input:checked {
      &,
      + label {
        display: block;
      }
    }
  }

  &__preview {
    padding: space(4);

    img {
      max-width: 100%;
    }
  }

  &__item {
    display: inline-block;
    margin-left: -$border-width-lg;
    color: color("gray-dark");
    cursor: pointer;
    border: $border-width-lg solid transparent;

    &--active {
      .#{$ns}color-picker__item__image {
        border-color: $border-active-color;
        border-radius: 50%;
      }
    }

    &__image {
      width: $color-picker-image-size;
      height: $color-picker-image-size;
      border: $border-width-lg solid transparent;
      border-radius: 50%;
    }
  }

  @include media-breakpoint-down(sm) {
    padding-right: 0;
    padding-left: 0;

    &__items-container {
      position: relative;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: $color-picker-image-size;
        height: $color-picker-image-size;
        content: "";
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, color("white") 100%);
      }
    }

    &__items {
      padding: 0 $color-picker-image-size space(2) (space(4) - $border-width-lg);
      margin-left: 0;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
    }

    &__selected {
      padding-left: space(4) * 2;
      margin-top: space(2);
    }
  }
}
