// stylelint-disable

@font-face {
  font-family: holmgrens-icons;
  src: url("#{$wdk-font-path}/holmgrens-icons/holmgrens-icons.eot");
  src: url("#{$wdk-font-path}/holmgrens-icons/holmgrens-icons.eot?#iefix") format("embedded-opentype"),
      url("#{$wdk-font-path}/holmgrens-icons/holmgrens-icons.woff") format("woff"),
      url("#{$wdk-font-path}/holmgrens-icons/holmgrens-icons.ttf") format("truetype"),
      url("#{$wdk-font-path}/holmgrens-icons/holmgrens-icons.svg#icomoon") format("svg");
  font-style: normal;
  font-weight: normal;
}
