/*
  Properties

  Use this component to present a list of key/value pairs.

  Markup: ../docs/markup/components/properties.hbs

  .properties--light    - Remove borders and lighten.

  Styleguide components.properties
*/

$properties-item-padding-y: 0.5rem !default;
$properties-item-padding-x: 0 !default;

.#{$ns}properties {
  font-size: $font-size-sm;

  &__item {
    display: flex;
    justify-content: space-between;
    padding: $properties-item-padding-y $properties-item-padding-x;
    border-bottom: $border-width solid $border-color;
  }

  > li:last-child {
    &,
    .#{$ns}properties__item:last-child {
      border-bottom: 0;
    }
  }

  &__item-header {
    padding-top: $properties-item-padding-y;

    + .#{$ns}properties__item {
      padding-top: 0;
    }
  }

  &__label {
    font-weight: $font-weight-light;
  }

  &__item-header,
  &__label--lg {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    line-height: $font-size-sm * 1.5;
    text-transform: uppercase;
  }

  &__value {
    font-weight: $font-weight-light;
    text-align: right;

    .#{$ns}price-tag--mark {
      box-shadow: inset 0px 1px 0 $white, inset 0px -1px 0 $white;
    }
  }

  &__text {
    font-weight: $font-weight-light;
  }

  &--light {
    .#{$ns}properties__item {
      padding: 0;
      border-bottom: 0;
    }

    .#{$ns}properties__label {
      &--lg {
        font-weight: $font-weight-normal;
        text-transform: none;
      }
    }
  }
}
