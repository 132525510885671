/*
  Settings

  Global variables for font, color definitions, etc.

  Weight: -5

  Styleguide settings
*/

@import "settings/all";

/*
  Tools

  Globally used mixins and functions.

  Weight: -4

  Styleguide tools
*/

@import "tools/all";

/*
  Generic

  Apply style normalization through `normalize.css` and set `box-sizing` globally.

  Weight: -3

  Styleguide generic
*/

@import "generic/all";

/*
  Elements

  Apply base styles to common elements. The classes defined here are not namespaced.

  Weight: -2

  Styleguide elements
*/

@import "elements/all";

/*
  Objects

  Selectors which define undecorated design patterns.

  Weight: -1

  Styleguide objects
*/

@import "objects/all";


/*
  Components

  Specific UI components.

  Styleguide components
*/

@import "components/all";

/*
  Utilities

  All utility classes are prefixed with `u-` and generally make use of `!important`.

  Styleguide utilities
*/

@import "utilities/all";
