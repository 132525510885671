// stylelint-disable selector-no-qualifying-type

/*
  Notification

  Convey an important message.

  Markup: <div class="notification {{modifier_class}}"><strong>Important!</strong> Remember to drink water.</button>

  Styleguide components.notification
*/

.#{$ns}notification {
  padding: $notification-padding-y $notification-padding-x;
  background-color: $yellow;

  @include media-breakpoint-down(sm) {
    font-size: $font-size-xs;
  }

}
