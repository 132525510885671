/*
  Tabs

  General purpose tabs. Each tab has a `.tab-content` associated with it that holds the content to show.

  If you have more than 2-3 tabs you may want to stack them at a certain breakpoint. Just use a media query and set `flex-direction: column` on `.tabs`.

  *Tabs requires the JS module `Tabs`.*

  Markup: ../docs/markup/components/tabs.hbs

  Styleguide components.tabs
*/

.#{$ns}tabs {
  display: flex;
  background: color("white");
  border: $border-width solid $border-color;
  border-bottom: 0;

  &__item {
    display: flex;
    flex: 1 0;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: color("gray-dark");
    cursor: pointer;
    border-bottom: $border-width solid $border-color;

    &--active {
      font-weight: $font-weight-bold;
      color: $body-color;
      cursor: default;
      border-bottom: $border-width-lg solid $border-active-color;
    }

    &:not(:last-child) {
      border-right: $border-width solid $border-color;
    }

    @include media-breakpoint-down(sm) {
      padding: 1.5rem 0;
    }
  }
}

.#{$ns}tab-content {
  display: none;
  padding: map-get($spacers, 5);

  &--active {
    display: block;
  }
}
