// stylelint-disable selector-list-comma-newline-after

/*
  Headings

  Markup:
  <h1>Header 1</h1>
  <h2>Header 2</h2>
  <h3>Header 3</h3>
  <h4>Header 4</h4>
  <h5>Header 5</h5>
  <h6>Header 6</h6>

  Styleguide elements.headings
*/

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
}

h1, .h1 {
  font-size: $h1-font-size;
  line-height: $h1-line-height;

  @include media-breakpoint-down(sm) {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }
}

h2, .h2 {
  font-size: $h2-font-size;
  line-height: $h2-line-height;

  @include media-breakpoint-down(sm) {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
  }
}

h3, .h3 {
  font-size: $h3-font-size;
  line-height: $h3-line-height;

  @include media-breakpoint-down(sm) {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
  }
}

h4, .h4 {
  font-size: $h4-font-size;
  line-height: $h4-line-height;

  @include media-breakpoint-down(sm) {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
  }
}

h5, .h5 {
  font-size: $h5-font-size;
  line-height: $h5-line-height;
}

h6, .h6 {
  font-size: $h6-font-size;
  line-height: $h6-line-height;
}
