/*
  Feedback

  Displays a feedback overlay on top of the form. Use it by adding `.active` on `.form-feedback` when the request is loading or has completed. If something went wrong, remove the class so the form controls regain visibility.

  Markup: ../docs/markup/components/forms/form-feedback.hbs

  Styleguide components.forms.feedback
*/

.#{$ns}form-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: space(5);
  text-align: center;
  background: color("gray-light");
  border: $border-width solid $border-color;
}

.#{$ns}form-feedback-host {
  position: relative;
}

.#{$ns}form-feedback-host .#{$ns}form-feedback {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-form-feedback;
  display: none;
  min-height: 100%;

  &.active {
    display: flex;
  }
}
