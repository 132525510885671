/*
  Modal

  Use modal to add dialogs to the site for lightboxes, user notifications, or completely custom content.

  Control the `max-width` of the dialog with:
  * `.modal-dialog--sm`
  * `.modal-dialog--lg`
  * `.modal-dialog--xl`

  *Modal requires the JS module `Modal`.*

  *HolmgrensVue offers a Vue implementation for this component: `VcModal`.*

  Markup: ../docs/markup/components/modal.hbs

  Styleguide components.modal
*/

/*
  Toggle

  Use `data` attributes to toggle the modal without writing any JS.

  Markup: ../docs/markup/components/modal/toggle.hbs

  Weight: -1

  Styleguide components.modal.toggle
*/

// Kill the scroll on the body
.#{$ns}modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.#{$ns}modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  padding: $modal-padding;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695

  .#{$ns}modal-open & {
    overflow-x: hidden;
    overflow-y: auto;
  }

  @include media-breakpoint-down(xs) {
    padding: $modal-padding-xs;
  }
}

// Shell div to position the modal with bottom padding
.#{$ns}modal-dialog {
  position: relative;
  width: auto;
  max-width: $modal-max-width-base;
  margin: 0 auto;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  &--centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (#{$modal-padding} * 2));
  }

  &--sm {
    max-width: $modal-max-width-sm;
  }

  &--lg {
    max-width: $modal-max-width-lg;
  }

  &--xl {
    max-width: $modal-max-width-xl;
  }

  &--lg,
  &--xl {
    @include media-breakpoint-up(lg) {
      .#{$ns}modal-content {
        padding: $modal-content-padding-lg;
      }

      .#{$ns}modal-footer {
        margin-bottom: -3.5rem;
      }
    }
  }
}

// When fading in the modal, animate it to slide down
.#{$ns}modal.#{$ns}fade .#{$ns}modal-dialog {
  transition: $modal-transition;
  transform: translate(0, -25%);
}
.#{$ns}modal.#{$ns}show .#{$ns}modal-dialog {
  transform: translate(0, 0);
}

// Actual modal
.#{$ns}modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  padding: $modal-content-padding;
  // counteract the pointer-events: none; in the .modal-dialog
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border-top: 0.5rem solid color("secondary");
  // Remove focus outline from opened modal
  outline: 0;
  box-shadow: $modal-content-box-shadow;
}

// Modal background
.#{$ns}modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.#{$ns}fade { opacity: 0; }
  &.#{$ns}show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.#{$ns}modal-header {
  display: flex;
  align-items: flex-start; // so the close btn always stays on the upper right corner
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  margin-bottom: 0.25rem;

  &__close {
    margin-left: auto;
    font-size: 1.25rem;
    color: color("gray-dark");
    cursor: pointer;
  }

  &__title {
    margin-bottom: 0;
    font-weight: $font-weight-bold;
    line-height: $modal-title-line-height;
  }
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.#{$ns}modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  font-size: $font-size-sm;
}

// Footer (for actions)
.#{$ns}modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: -2rem;

  &__close {
    color: color("primary");
    text-align: center;

    label {
      display: block;
      margin-bottom: -0.25rem;
      font-size: 0.75rem;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      cursor: pointer;
    }

    button {
      @extend .#{$ns}btn;
      @extend .#{$ns}btn--circle;
      @extend .#{$ns}btn--sm;
      @extend .#{$ns}icon;
      @extend .#{$ns}icon-close;
      background: $white;
    }
  }
}
