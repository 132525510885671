// stylelint-disable declaration-no-important

/*
  Typography

  Utility classes that alter the element's typography.

  Styleguide utilities.typography
*/

/*
  Font weight

  Uses the `fw-` prefix.

  `.u-fw-light` applies the font-weight of `$font-weight-light`.

  `.u-fw-normal` applies the font-weight of `$font-weight-normal`.

  `.u-fw-bold` applies the font-weight of `$font-weight-bold`.

  `.u-fw-black` applies the font-weight of `$font-weight-black`.

  Styleguide utilities.typography.font-weight
*/

.u-fw-light {
  font-weight: $font-weight-light !important;
}

.u-fw-normal {
  font-weight: $font-weight-normal !important;
}

.u-fw-bold {
  font-weight: $font-weight-bold !important;
}

.u-fw-black {
  font-weight: $font-weight-black !important;
}

/*
  Color

  Uses the `c-` prefix.

  `.u-c-muted` applies the color of `$text-muted`.

  Styleguide utilities.typography.color
*/

.u-c-muted {
  color: $text-muted !important;
}
