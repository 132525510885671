// stylelint-disable

@font-face {
  font-family: "Museo Sans";
  font-style: italic;
  font-weight: 100;
  src: url("#{$wdk-font-path}/museosans/905291/b2f70e54-26ae-44c8-94e7-3a7590a95d50.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905291/b2f70e54-26ae-44c8-94e7-3a7590a95d50.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905291/57a6b494-1c77-4cb0-b042-4adca2c0bdb1.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905291/2ab97fdf-1e06-4c25-a5e1-b111273d580a.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905291/ba402481-70a4-42b6-a4d2-291fac0e1202.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 100;
  src: url("#{$wdk-font-path}/museosans/905294/e14e9fd6-0631-4491-b873-211c7b6f926c.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905294/e14e9fd6-0631-4491-b873-211c7b6f926c.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905294/fd9d5b5d-71c1-4db0-bf20-cd7e3ce4d069.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905294/e976d680-12b6-411e-9f79-0ef9287f484d.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905294/81780024-f1ae-4135-a0ae-d0bab2c19986.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: italic;
  font-weight: 300;
  src: url("#{$wdk-font-path}/museosans/905297/90e6d1d9-33c0-4c63-9491-85041f7d5fd3.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905297/90e6d1d9-33c0-4c63-9491-85041f7d5fd3.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905297/a0f222f7-39b4-41c9-a5c0-0b9b7e6b8f89.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905297/96766c08-0e19-43a8-b45d-e95c5b09785c.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905297/304f4215-6b57-433a-83f7-2d4c3aaf82e9.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 300;
  src: url("#{$wdk-font-path}/museosans/905300/fd479df2-01b0-4e3a-a411-915c477f1352.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905300/fd479df2-01b0-4e3a-a411-915c477f1352.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905300/1d3191cd-ae8c-45e2-bb04-11e96d8fa974.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905300/b252d464-65cb-4950-88f7-ac0a1bf79b75.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905300/b3d18117-6517-4d3f-afc1-774086ff5fc1.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 500;
  src: url("#{$wdk-font-path}/museosans/905303/a29947fd-0c53-492b-b2f4-399b01153b30.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905303/a29947fd-0c53-492b-b2f4-399b01153b30.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905303/d9896899-f08c-4750-a874-a9e10d83c2cb.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905303/d42dd843-62b0-4623-8855-d5882512c3c9.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905303/6d532ccb-086c-4000-bab2-5d16862fcf01.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: italic;
  font-weight: 500;
  src: url("#{$wdk-font-path}/museosans/905306/afb0bae6-4f02-492e-82dd-c4c51e519279.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905306/afb0bae6-4f02-492e-82dd-c4c51e519279.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905306/b76ff444-27dd-4494-b96e-2f5157f347d5.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905306/136eb3cf-249c-40a3-bb62-49c1ad9ae6d8.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905306/70c24c76-d52f-4d55-be08-6085ad014f1b.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: italic;
  font-weight: 700;
  src: url("#{$wdk-font-path}/museosans/905309/8507714a-41e5-4f02-9f2d-70e6dc6b30f7.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905309/8507714a-41e5-4f02-9f2d-70e6dc6b30f7.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905309/0db19ee1-b74a-4eb6-b48f-60c7047ea44b.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905309/9c98d320-d511-4d3d-9ba3-6516302dba95.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905309/b4b164e1-3b4c-42c1-8db5-ca02bfad125c.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 700;
  src: url("#{$wdk-font-path}/museosans/905312/3d0a39cf-115c-4800-9b1e-31c7912abfc2.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905312/3d0a39cf-115c-4800-9b1e-31c7912abfc2.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905312/e2d37f34-6bca-473c-b64d-93811f4dc046.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905312/eadbb595-a394-48fc-834f-8bf5da15cddf.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905312/9bcba093-b507-4b9b-9c83-303003d3815c.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: italic;
  font-weight: 900;
  src: url("#{$wdk-font-path}/museosans/905315/671bc72b-b38c-4bdd-8ec8-6cb9976a3596.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905315/671bc72b-b38c-4bdd-8ec8-6cb9976a3596.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905315/83ec39ba-ad2e-46a6-a1b1-f93f1457f7d4.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905315/39ac4994-8f69-4ff0-8acd-9f100664da8f.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905315/cffeecea-caad-4fed-835f-21cc1de30c4b.ttf") format("truetype");
}

@font-face {
  font-family: "Museo Sans";
  font-style: normal;
  font-weight: 900;
  src: url("#{$wdk-font-path}/museosans/905318/7e90c1ee-e4b1-46f0-9134-93a8ba421545.eot?#iefix");
  src: url("#{$wdk-font-path}/museosans/905318/7e90c1ee-e4b1-46f0-9134-93a8ba421545.eot?#iefix") format("eot"),
       url("#{$wdk-font-path}/museosans/905318/009ca58d-46af-4651-a359-f0ae2301085b.woff2") format("woff2"),
       url("#{$wdk-font-path}/museosans/905318/3619b5f4-8df9-44f5-8c53-b7c236e9c74e.woff") format("woff"),
       url("#{$wdk-font-path}/museosans/905318/9b2d949a-4e1c-435c-8412-f377c80583ae.ttf") format("truetype");
}
