// stylelint-disable selector-no-qualifying-type

/*
  Car configurator

  Styleguide components.configurator
*/

/*
  Hero

  Markup: ../docs/markup/components/hero.hbs

  *Hero requires the JS module `Slider`, which depends on [Swiper](http://idangero.us/swiper/get-started/).*

  Styleguide components.configurator.hero
*/

.#{$ns}hero {
  padding: map-get($spacers, 6) 0;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  a:first-of-type {
    display: block;
    margin-bottom: map-get($spacers, 4);
  }

  &__title {
    margin-bottom: 0;
    font-weight: $font-weight-black;
  }

  &__byline {
    font-weight: $font-weight-light;
    @include media-breakpoint-down(sm) {
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
    }
  }

  &__image {
    position: relative;
    margin-top: map-get($spacers, 3);
    img {
      max-width: 100%;
    }
    &__btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  &__modal-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__slider {
    margin-right: - map-get($spacers, 4);
    margin-bottom: map-get($spacers, 4);
    margin-left: - map-get($spacers, 4);


    img {
      width: 100%;
    }

    .btn {
      position: absolute;
      top: 40%;
      left: 1rem;
      z-index: $zindex-dropdown;
      color: $black;
      background: $white;
      border: none;
      transform: rotate(180deg);

      &.modal--slider__next {
        right: 1rem;
        left: auto;
        transform: none;
      }
    }

    &__video-wrapper {
      position: relative;
      height: 0;
      padding-top: 30px;
      padding-bottom: 56.25%; /* 16:9 */
      overflow: hidden;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  // Overrides for components default styles
  ul {
    margin-bottom: map-get($spacers, 4);
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      margin-bottom: 0;
    }
    li {
      @include media-breakpoint-up(md) {
        font-size: $font-size-base;
        font-weight: $font-weight-bold;
      }
      @include media-breakpoint-down(sm) {
        position: relative;
        display: inline-block;
        padding-left: map-get($spacers, 4);
      }
    }
  }
  .mark {
    display: inline-block;
    margin-bottom: map-get($spacers, 2);
  }
}

// Modal
.#{$ns}hero-info-modal {
  .#{$ns}modal-content {
    @include media-breakpoint-up(md) {
      .#{$ns}modal-body {
        li {
          margin-bottom: map-get($spacers, 2);
          font-size: $font-size-base;
          font-weight: $font-weight-bold;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .#{$ns}modal-body {
        li {
          margin-bottom: map-get($spacers, 2);
          font-size: $font-size-sm;
        }
      }
    }
  }
}

