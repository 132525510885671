/*
  Loader

  Display a loader with an optional message for pending requests. Has three states: loading (no modifier), success (`.loader--success`), and failure (`.loader--fail`).

  *HolmgrensVue offers a Vue implementation for this component: `VcLoader`.*

  Markup: ../docs/markup/components/loader.hbs

  .loader--success       - If the action succeeds.
  .loader--fail          - If the action fails.

  Styleguide components.loader
*/

.#{$ns}loader {
  margin-bottom: 1rem;
  text-align: center;

  &__shape {
    position: relative;
    display: inline-block;
    width: $loader-size;
    height: $loader-size;
    margin-bottom: 1rem;
    vertical-align: top;
    border: $loader-border-width solid $loader-border-color;
    border-left-color: $loader-border-active-color;
    border-radius: 50%;
    animation: loader-spin 1.2s infinite linear;
  }

  &__symbol {
    @extend .#{$ns}icon;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: $font-weight-black;
    opacity: 0;
  }

  &__header {
    font-size: $lead-font-size;
    font-weight: $font-weight-black;
    line-height: $lead-line-height;
  }

  &__text {
    margin-top: $headings-margin-bottom;
  }

  &--success,
  &--fail {
    .#{$ns}loader__shape {
      transition: border 0.4s ease-out;
      animation: none;
    }

    .#{$ns}loader__symbol {
      opacity: 1;
      transition: opacity 0.22s ease-in;
    }
  }

  &--success {
    .#{$ns}loader__shape {
      border-color: color("success");
    }

    .#{$ns}loader__symbol {
      @extend .#{$ns}icon-checkmark;
      font-size: 3rem;
      color: color("success");
    }
  }

  &--fail {
    .#{$ns}loader__shape {
      border-color: color("danger");
    }

    .#{$ns}loader__symbol {
      @extend .#{$ns}icon-close;
      font-size: 2.25rem;
      color: color("danger");
    }
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
