/*
  Option list

  Customizable component to present options to the user. If there are tons of options you should use Dropdown instead.

  *Option list requires the JS module `OptionList`.*

  *HolmgrensVue offers a Vue implementation for this component: `VcOptionList`.*

  Markup: ../docs/markup/components/option-list.hbs

  .option-list--column     - Display options vertically.

  Styleguide components.option-list
*/

@mixin option-list-border-left() {
  .#{$ns}option-list__item {
    border-bottom: $border-width solid $border-color;
    border-left: $border-width-lg solid transparent;

    &:first-child {
      border-top: $border-width solid $border-color;
    }

    &--active {
      border-bottom: $border-width solid $border-color;
      border-left: $border-width-lg solid $border-active-color;
    }
  }
}

@mixin option-list-border-bottom() {
  border: $border-width solid $border-color;

  .#{$ns}option-list__item {
    border-right: $border-width solid $border-color;
    border-bottom: $border-width solid $border-color;

    &:last-child {
      border-right: 0;
    }

    &--active {
      border-bottom: $border-width-lg solid $border-active-color;
    }
  }
}

.#{$ns}option-list {
  @extend .#{$ns}list-unstyled;
  display: flex;

  &__item {
    flex: 1 0;
    padding: space(4);
    padding-left: space(4) * 2;
    color: color("gray-dark");
    cursor: pointer;
    background: color("white");

    .#{$ns}form-check-input {
      margin-top: 1px;
    }

    &--active {
      color: $body-color;
      cursor: default;

      .#{$ns}option-list__item__header {
        font-weight: $font-weight-bold;
      }
    }

    /*
      Centered items

      Markup: ../docs/markup/components/option-list/centered-items.hbs

      Styleguide components.option-list.centered-items
    */

    &--centered {
      display: flex;
      // align-items: center;
      justify-content: center;
      padding-left: 1.5rem;

      // @include media-breakpoint-down(xs) {
      //   display: block;
      //   padding-left: 3.25rem;
      // }
    }
  }

  &__content {
    margin-top: space(2);
    font-size: $font-size-sm;
    line-height: $line-height-sm;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-xs;
    }
  }

  &__expand {
    margin-top: space(2);

    &__action {
      font-size: $font-size-xs;
      color: color("gray-dark");
      text-decoration: underline;
      text-transform: uppercase;
      cursor: pointer;
    }

    &__content {
      margin-top: space(2);
      margin-bottom: space(2);
    }
  }

  &__header {
    font-weight: $font-weight-bold;

    label {
      cursor: pointer;
    }

    &__meta {
      font-size: $font-size-sm;

      @include media-breakpoint-down(sm) {
        font-size: $font-size-xs;
      }
    }

    &--aside {
      display: flex;
      justify-content: space-between;
    }
  }

  /*
    Multiselect

    Markup: ../docs/markup/components/option-list/multiselect.hbs

    Styleguide components.option-list.multiselect
  */

  &--multiselect {
    .#{$ns}option-list__item {
      color: $body-color;
    }
  }

  &--column {
    display: block;
  }

  @include media-breakpoint-down(xs) {
    &:not(.#{$ns}option-list--multiselect):not(.#{$ns}option-list--column--border-bottom) {
      @include option-list-border-left();
    }

    &.#{$ns}option-list--column--border-bottom {
      @include option-list-border-bottom();
    }
  }

  @include media-breakpoint-up(sm) {
    &:not(.#{$ns}option-list--multiselect) {
      @include option-list-border-bottom();
    }

    &.#{$ns}option-list--multiselect {
      .#{$ns}option-list__item {
        border: $border-width solid $border-color;

        &:not(:last-child) {
          border-bottom: 0;
        }
      }
    }
  }
}

