// stylelint-disable

@font-face {
  font-family: icomoon;
  src: url("#{$wdk-font-path}/icomoon/icomoon.eot?sghjte");
  src: url("#{$wdk-font-path}/icomoon/icomoon.eot?sghjte#iefix") format("embedded-opentype"),
      url("#{$wdk-font-path}/icomoon/icomoon.ttf?sghjte") format("truetype"),
      url("#{$wdk-font-path}/icomoon/icomoon.woff?sghjte") format("woff"),
      url("#{$wdk-font-path}/icomoon/icomoon.svg?sghjte#icomoon") format("svg");
  font-style: normal;
  font-weight: normal;
}
