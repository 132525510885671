/*
  Namespace

  It's possible to namespace all CSS & JS classes for compatibility with other frameworks.

  Modify scss variable `$wdk-namespace` before including main.scss and global variable
  `window.wdkNamespace` in JS to use this feature.

  *In WDK's source, it's important that all selectors are prefixed with `$ns`/`window.wdkNamespace`.*

  Styleguide settings.namespace
*/

$wdk-namespace: "" !default;
$ns: $wdk-namespace !default;
