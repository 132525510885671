// stylelint-disable selector-no-qualifying-type

/*
  Lists

  Apply style to your `<ul>`'s and `<ol>`'s by adding `.list-styled`.

  Styleguide components.lists
*/

/*
  Unstyled list

  Markup: <ul class="list-unstyled"><li>List item 1</li><li>List item 2</li></ul>

  Styleguide components.lists.unstyled
*/

.#{$ns}list-unstyled {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

.#{$ns}list-styled {
  padding-left: 0;
  list-style: none;

  li {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    font-size: $font-size-sm;

    &::after,
    &::before {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

/*
  Numbered list

  Markup: <ol class="list-styled"><li>List item 1</li><li>List item 2</li></ol>

  Styleguide components.lists.numbered
*/

ol.#{$ns}list-styled {
  align-items: flex-start;
  counter-reset: numlist;

  li {
    padding-left: 1.3rem;

    &::after {
      font-size: 1.25rem;
      font-weight: $font-weight-black;
      color: color("secondary");
      content: counter(numlist);
      counter-increment: numlist;
    }
  }
}

/*
  Bullet list

  Markup: <ul class="list-styled"><li>List item 1</li><li>List item 2</li></ul>

  Styleguide components.lists.unordered
*/

ul.#{$ns}list-styled {
  li {
    padding-left: 1rem;

    &::after {
      width: 8px;
      height: 8px;
      margin-top: 7px;
      content: "";
      background: color("secondary");
      border-radius: 50%;
    }
  }
}

/*
  Checklist

  Markup: <ul class="list-styled list-styled--checklist"><li>List item 1</li><li>List item 2</li></ul>

  Styleguide components.lists.checklist
*/

ul.#{$ns}list-styled--checklist {

  li {
    @extend .#{$ns}icon;
    @extend .#{$ns}icon-checkmark;
    padding-left: 1.8rem;

    &::after {
      width: 18px;
      height: 18px;
      margin-top: 2px;
    }

    &::before {
      z-index: 1;
      margin-top: -2px;
      font-size: 1.5rem;
      font-weight: $font-weight-black;
    }
  }
}
