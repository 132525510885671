/*
  Price tag

  Display a price. For SEK, use "kr" and Swedish number format: whitespace separator and comma for decimals.

  Markup: <div class="price-tag {{modifier_class}}">3 595 kr</div>

  .price-tag--obsolete     - Show an obsolete price when there is a discount.
  .price-tag--mark         - Mark the price. Extends the style of `.mark`.
  .price-tag--total        - Large price tag to show the total amount.

  Styleguide components.price-tag
*/

.#{$ns}price-tag {
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;

  &--obsolete {
    font-weight: $font-weight-normal;
    color: $text-muted;
    text-decoration: line-through;
  }

  &--mark {
    @extend .mark;
  }

  &--total {
    font-size: 1.625rem;
    font-weight: $font-weight-black;

    @include media-breakpoint-down(sm) {
      font-size: $font-size-lg;
    }
  }
}
