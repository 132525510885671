/*
  Functions

  Styleguide tools.functions
*/

/*
  color-yiq($color)

  Return the contrasted dark or light color.

  Styleguide tools.functions.color-yiq
*/

@function color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= $yiq-contrasted-threshold) {
    @return $yiq-text-dark;
  } @else {
    @return $yiq-text-light;
  }
}

/*
  color($key)

  Retrieve a color from the `$colors` map.

  Pass the parameter as a string: `color("red")` (not `color(red)`).

  Styleguide tools.functions.color
*/

@function color($key: "primary") {
  @return map-get($colors, $key);
}

/*
  gray($key)

  Retrieve a gray from the `$grays` map.

  Weight: -1

  Styleguide tools.functions.gray
*/

@function gray($key: 100) {
  @return map-get($grays, $key);
}

/*
  space($key)

  Retrieve a spacer from the `$spacers` map.

  Styleguide tools.functions.space
*/

@function space($key: 0) {
  @return map-get($spacers, $key);
}
