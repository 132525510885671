/*
  Pamphlet

  Present a page-based summary.

  Markup: ../docs/markup/components/pamphlet.hbs

  Styleguide components.pamphlet
*/

.#{$ns}pamphlet {
  padding: 1.5rem;
  background: color("secondary");

  .#{$ns}properties__item {
    border-bottom-color: color("gold");
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    padding: 0;
    overflow: hidden;
    background: none;

    &__page {
      flex: 1 0;
      padding: 1.5rem;
      background: color("secondary");

      &:nth-child(n+2) {
        box-shadow: $box-shadow;
      }
    }
  }
}
