/*
  Links

  Markup: <a href="#">Visit me</a>

  Styleguide elements.links
*/

a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}
