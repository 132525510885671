/*
  Slider modal

  Modal with a slider. Requires [Swiper](http:idangero.us/swiper/get-started/).

  *Slider modal requires the JS module `Slider`, which depends on [Swiper](http:idangero.us/swiper/get-started/).*

  Markup: ../docs/markup/components/modal/slider.hbs

  Styleguide components.modal.slider
*/

// Modal slider
.#{$ns}modal.#{$ns}modal--slider {
  .#{$ns}btn {
    position: absolute;
    top: 40%;
  }

  .#{$ns}modal--slider__next {
    right: -5.5rem;
  }

  .#{$ns}modal--slider__prev {
    left: -5.5rem;
    transform: rotate(180deg);
  }

  .#{$ns}icon-close-circle {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 90px;
    color: color("secondary");
    cursor: pointer;
  }

  .#{$ns}modal-content {
    padding: 100px;
    background-color: transparent;
    border-top: none;
    box-shadow: none;

    span {
      display: block;
      width: 100%;
      margin-top: map-get($spacers, 4);
      color: $white;
      text-align: center;
    }

    .#{$ns}modal--slider__video-wrapper {
      position: relative;
      height: 0;
      padding-top: 30px;
      padding-bottom: 56.25%; /* 16:9 */
      overflow: hidden;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }
}
