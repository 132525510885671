$btn-padding-y:               0.9375rem !default;
$btn-padding-x:               1.9375rem !default;
$btn-line-height:             $line-height-base !default;

$btn-padding-y-sm:            0.75rem !default;
$btn-padding-x-sm:            1.5rem !default;
$btn-line-height-sm:          $line-height-sm !default;

$btn-padding-y-lg:            $btn-padding-y !default;
$btn-padding-x-lg:            $btn-padding-x !default;
$btn-line-height-lg:          $line-height-lg !default;

$btn-border-width:            $border-width !default;

$btn-font-weight:             $font-weight-bold !default;
$btn-font-weight-sm:          $btn-font-weight !default;
$btn-font-weight-lg:          $btn-font-weight !default;

$btn-box-shadow:              inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width:             0 !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        0.5 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-disabled-color:     $gray-700 !default;

$btn-block-spacing-y:         0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius-lg !default;
$btn-border-radius-lg:        $btn-border-radius !default;
$btn-border-radius-sm:        $btn-border-radius !default;

$btn-transition:              color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;
