/*
  Labels

  Markup: <label>One modest label</label>

  Styleguide elements.labels
*/

label {
  display: inline-block;
  margin-bottom: $label-margin-bottom;
  font-weight: $font-weight-normal;
}
